import React from "react";
import { Button } from "antd-mobile";
import type { ButtonProps } from "antd-mobile";
import classNames from "classnames";
import styles from "./index.module.scss";
interface IButtonsProps extends ButtonProps {}

/** 按钮  */
const Buttons: React.FC<IButtonsProps> = (props) => {
  const { color, className, fill = "solid", ...restProps } = props;

  return (
    <Button
      {...restProps}
      fill={fill}
      color={color}
      className={classNames(
        {
          [styles.button]: color === "primary" && fill === "solid",
          [styles.buttonPN]: color === "primary" && fill === "none",
        },
        className
      )}
    />
  );
};

export default Buttons;

/**
 * 手机号正则
 */
export const phoneRegExp = /^1[3-9]\d{9}$/;

/**
 * 社会信用代码 正则
 */
export const socialCreditCodeRegExp = /^[0-9A-Z]{18}$/;

/**
 * 身份证号正则
 */
export const idCardRegExp =
  /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

import axios from "@/common/Utils/APIClient";
interface IParams {
  /**
   * dd授权码
   */
  authCode: string;
  /**
   * 企业id
   */
  corpId: string;
}
export enum GrantEnum {
  /**
   * 未授权
   */
  False = 0,
  /**
   * 已授权
   */
  True = 1,
}
export interface UserInfo {
  success: boolean;
  msg: string;
  data: UserInfoData;
}
export interface UserInfoData {
  id: string;
  gmtCreate: Date;
  gmtModified: Date;
  corpId: string;
  parentDeptId: string;
  deptId: string;
  staffId: string;
  staffName: string;
  staffPhone: string;
  alipayEmployeeId: string;
  /**
   * 支付宝签约地址
   */
  signUrl: string;
  /**
   * 是否授权,0 未授权，1 已授权
   */
  isGrant: GrantEnum;
  isPush: number;
  isBindQiyema: number;
  status: number;
}
export const getUserInfo = (params: IParams) => {
  return axios
    .get("/dmall_customer/staff/getUserInfo", { param: params })
    .then((res: UserInfo) => res.data);
};

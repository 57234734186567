import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
// import { Link } from "react-router-dom";
import { useRequest } from "ahooks";
import { Card, Image, Modal, Swiper, Toast } from "antd-mobile";
import { useSearchParams } from "react-router-dom";
import { redirect } from "src/common/Utils";
import { deleteBankCard } from "./Services";
import style from "./index.module.scss";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { Accounts, getBankCard } from "../Wallet/Services";
import classNames from "classnames";
import Buttons from "@/common/Component/Button";
import BankCard from "./Component/BankCard";
import { useOpenUrl } from "@/hooks/useOpenUrl";

const TOAST_LOADING = {
  duration: 0,
  icon: "loading",
  content: "正在解绑中…",
};

function OneCard() {
  const [params] = useSearchParams();
  const openUrl = useOpenUrl();
  const swiperRef = useRef<any>(null);
  const { run: removeCard } = useRequest(deleteBankCard, {
    manual: true,
    onSuccess: (res: any) => {
      const { success = false, msg } = res || {};
      if (success) {
        Toast.clear();
        Toast.show({
          icon: "success",
          content: "解绑成功",
          afterClose: () => {
            // console.log(444, res);
            redirect(`/wallet`);
          },
        });
        return;
      }

      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "解绑失败，请重试!",
      });
    },
    onError: (err: any) => {
      const { msg } = err || {};
      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "解绑失败，请重试!",
      });
    },
  });
  const [hasBind, setHasBind] = useState<boolean>(false);
  const { data: listBackCard } = useRequest(getBankCard, {});

  const [userInfo, corpId] = useUserStore(
    useShallow((state) => [state.dingUserInfo, state.corpId, state.appType])
  );
  useEffect(() => {
    const index = listBackCard?.data?.findIndex(
      (item) => item?.enterpriseApplyDTO?.applyOrgId === corpId
    );

    if (index === -1) {
      setHasBind(true);
    } else {
      setHasBind(false);
    }
    swiperRef.current?.swipeTo &&
      swiperRef.current.swipeTo(index === -1 ? 0 : index);
  }, [listBackCard, corpId]);
  // useEffect(() => {
  //   dd.biz.navigation.setLeft({
  //     control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
  //     text: "", //控制显示文本，空字符串表示显示默认文本
  //     android: true,
  //     onSuccess: function () {
  //       if (appType === "app") {
  //         openUrl({ url: "/tabBar/my" });
  //       }
  //       if (appType === "h5") {
  //       }
  //     },
  //     // onFail: function () {},
  //   });
  // }, [appType]);

  const removeBankCard = useCallback(() => {
    Modal.confirm({
      showCloseButton: true,
      content: "确认解除绑定？",
      onConfirm: () => {
        Toast.show(TOAST_LOADING);
        removeCard({ cardId: params.get("cardId") });
      },
    });
  }, []);

  enum CardTypeEnum {
    Card = "card",
    Add = "add",
  }

  const showSwiper: Array<{
    type: CardTypeEnum;
    data?: Accounts;
  }> = useMemo(() => {
    const cardList = listBackCard?.data?.map((item) => ({
      type: CardTypeEnum.Card,
      data: item,
    }));
    if (hasBind) {
      return [
        {
          type: CardTypeEnum.Add,
        },
        ...(cardList?.length ? cardList : []),
      ];
    }
    return cardList || [];
  }, [listBackCard, hasBind]);
  const tipsData = [
    {
      title: "安全",
      icon: "https://cdn.wuhuxianmai.cn/feed/1733480748177-37a0c3bbeb823aa190daa51675c7e52800c240a3db0bc7b4c4dc6fadcabe0830",
    },
    {
      title: "便捷",
      icon: "https://cdn.wuhuxianmai.cn/feed/1733480750439-06ff9f6f96468682c8e80b6dc52aa2d2924c597b88bd5cec82bc36dc215806d2",
    },
    {
      title: "高效",
      icon: "https://cdn.wuhuxianmai.cn/feed/1732593228706-b69054ea0660064ff15ec1cb1603aabccf0472e6d1bc8264fa6a403f44393751",
    },
  ];

  return (
    <div className={style.app}>
      <div className={style.container}>
        <ul className={style.header}>
          <li>{userInfo?.userName}</li>
          <li>欢迎使用芜湖本地生活</li>
        </ul>
        {
          <Swiper
            ref={swiperRef}
            slideSize={95}
            className={classNames(style.swiper, {
              [style.oneSwiper]: showSwiper?.length === 1,
            })}
            indicatorProps={{
              color: "white",
            }}
            indicator={(total, count) => {
              if (total <= 1) return null;
              return (
                <div className={style.customIndicator} key={count}>
                  {new Array(total).fill(1).map((_, i) => (
                    <div
                      key={i}
                      className={classNames(style.customIndicatorItem, {
                        [style.active]: i === count,
                      })}
                    />
                  ))}
                </div>
              );
            }}
          >
            {showSwiper?.map((item, index) => {
              if (item.type === CardTypeEnum.Card) {
                return (
                  <Swiper.Item key={index}>
                    <BankCard data={item?.data} />
                  </Swiper.Item>
                );
              } else {
                return (
                  <Swiper.Item key={index}>
                    <Card
                      className={classNames(style.card)}
                      title={"开通钱包，领福利"}
                      headerClassName={style.cardHeader}
                      bodyClassName={style.cardBody}
                    >
                      <Image
                        src="https://cdn.wuhuxianmai.cn/feed/1733480754546-0cdb60b42465245addf4d19f72ddb7fba2de27477274b81fd5fbc8569379e425"
                        className={style.image}
                      />
                      <div className={style.tips}>
                        {tipsData.map((item, index) => (
                          <div key={index}>
                            <div className={style.tipsItem}>
                              <Image
                                src={item.icon}
                                className={style.tipsItemIcon}
                              />
                              <div className={style.tipsItemText}>
                                {item.title}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Buttons
                        fill="solid"
                        color="primary"
                        block
                        className={style.button}
                        onClick={() =>
                          openUrl({ url: "/bankCardAdd", isRoute: true })
                        }
                      >
                        单位已帮我开通，去绑定
                      </Buttons>
                    </Card>
                  </Swiper.Item>
                );
              }
            })}
          </Swiper>
        }
      </div>
    </div>
  );
}

export default OneCard;

import React from "react";
import styles from "./index.module.scss";

interface IFormCardTitleProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
}

/** 表单卡片  */
const FormCardTitle: React.FC<IFormCardTitleProps> = (props) => {
  const { children, title, subTitle } = props;

  return (
    <div className={styles.formCard}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  );
};

export default FormCardTitle;

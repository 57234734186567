import axios from "@/common/Utils/APIClient";
interface IParams {
  /**
   * dd授权码
   */
  authCode: string;
  /**
   * 企业id
   */
  corpId: string;
  unionId: string;
}

export interface UserInfo {
  success: boolean;
  msg: string;
  data: any;
}

/**
 * 钉钉用户确认授权-用户授权后调用，保存用户信息
 * @param params
 * @returns
 */
export const userDingConfirm = (params: IParams): Promise<UserInfo> => {
  return axios.get("/dmall_customer/staff/userConfirm", { param: params });
};

import React, { useEffect, useRef } from "react";
import classNames from "classnames";

import Icon from "src/common/Component/Icon";

import styles from "./index.module.scss";

interface IToTopProps {
  /**
   * 滚动目标元素
   */
  target?: HTMLElement;
  /**
   * 滚动高度达到此参数值时才显示组件
   */
  offset?: number;
  className?: string;
  /**
   * 是否安全区域底部
   */
  safeArea?: boolean;
}

/** 到顶部  */
const ToTop: React.FC<IToTopProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  const parent = ref.current?.parentElement;
  const { target = parent, offset = 100, className, safeArea } = props;

  const [hasShow, setHasShow] = React.useState(false);

  const goToTop = () => {
    if (!ref.current) return;
    if (target) {
      target.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (!ref.current) return;
    const parent = ref.current.parentElement;
    const scrollHandler = () => {
      if (parent === null) {
        setHasShow(false);
        return;
      }
      setHasShow(parent.scrollTop > offset);
    };
    parent && parent.addEventListener("scroll", scrollHandler);
    return () => {
      parent!.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <div
      className={classNames(styles.toTop, className, {
        [styles.active]: hasShow,
        [styles.safeArea]: safeArea,
      })}
      onClick={goToTop}
      ref={ref}
    >
      <Icon className={styles.up} icon="icon-dingbu" />
      <div>顶部</div>
    </div>
  );
};

export default ToTop;

import React, { useEffect, useMemo } from "react";
import styles from "./index.module.scss";
import { Image, Toast } from "antd-mobile";
import Buttons from "@/common/Component/Button";
import copy from "copy-to-clipboard";
import Icon from "@/common/Component/Icon";
import { IApplyResData, StatusEnum } from "../../services/enterpriseApplyGet";
import { useRequest } from "ahooks";
import { getAccountDetail } from "../../services/getAccountDetail";
interface ICheckProps {
  data?: IApplyResData;
  /**重新填写*/
  onReApply?: () => void;
}
const url = "https://meal.wuhuxianmai.cn";

/** 检查  */
const Check: React.FC<ICheckProps> = (props) => {
  const { data, onReApply } = props;
  const {
    data: detailData,
    loading,
    run: getAccountDetailRun,
  } = useRequest(getAccountDetail, {
    manual: true,
  });
  const status = useMemo(() => {
    if (data?.status === StatusEnum.ReviewFailed) {
      return "fail";
    }
    if (data?.status === StatusEnum.PendingPlatformReview) {
      return "wait";
    }

    return "success";
  }, [data]);
  const imageUrl = useMemo(() => {
    if (data?.status === StatusEnum.ReviewFailed) {
      return "https://cdn.wuhuxianmai.cn/feed/1732505912398-0da006d19473604a7aec9f6008dd5d27dab3f8b1b663305bb63587e8d67e06a2";
    }
    if (data?.status === StatusEnum.PendingPlatformReview) {
      return "https://cdn.wuhuxianmai.cn/feed/1732505915295-1312d12f901e9afdb22b56de3c8f9b0ee304eb47c9e3605b4404a427062c1a14";
    }
    return "https://cdn.wuhuxianmai.cn/feed/1732505917991-f546ad46b439443e2944d0515a2b7e391433930e40140966bd804308985401a0";
  }, [data]);
  const title = useMemo(() => {
    switch (data?.status) {
      case StatusEnum.PendingPlatformReview:
        return "审核中";
      case StatusEnum.ReviewFailed:
        return "审核未通过";
      case StatusEnum.Cancelled:
        return "已注销";
      case StatusEnum.Void:
        return "已作废";
      case StatusEnum.ReviewPassed:
        return "审核通过";
      case StatusEnum.AccountBookOpened:
        return "企业账户已开通";
      case StatusEnum.AccountBookBlock:
        return "企业账户已冻结";
      default:
        return "审核中";
    }
  }, [data]);
  const copyText = (values: string) => {
    const isCopy = copy(values, {});
    isCopy ? Toast.show("复制成功") : Toast.show("复制失败");
  };
  const showPhone = useMemo(() => {
    if (!data?.contactPhone) return "";
    // 展示手机号码，部分加密
    const str = `${data?.contactPhone?.substring(
      0,
      3
    )}****${data?.contactPhone?.substring(7)}`;
    return str;
  }, [data]);
  useEffect(() => {
    if (data?.accountId) {
      getAccountDetailRun({ id: data.accountId });
    }
  }, [data]);
  return (
    <div className={styles.result}>
      <Image className={styles.image} src={imageUrl} />
      <div className={styles.title}>{title}</div>
      {status === "wait" && (
        <div className={styles.info}>
          您的入驻申请已提交，工作人员会在2个工作日内审核，请耐心等待
        </div>
      )}
      {status === "fail" && (
        <div className={styles.info}>{data?.applyNote || "申请失败"}</div>
      )}
      {status === "fail" && (
        <Buttons
          className={styles.failButton}
          color="primary"
          onClick={() => {
            onReApply && onReApply();
          }}
        >
          重新填写
        </Buttons>
      )}
      {status === "success" && (
        <div className={styles.info}>
          <div>
            <div>请使用电脑登录系统管理餐补，地址如下</div>
            <div>
              {url}
              <Icon
                icon="icon-fuzhi"
                className={styles.copy}
                onClick={() => copyText(url)}
              />
            </div>
          </div>
          <div>登录账号为您的手机号码{showPhone}</div>
          <div>密码已发送至您的手机，请注意查收</div>
        </div>
      )}
      {status === "success" && (
        <div className={styles.success}>
          <div className={styles.successTitle}>企业餐补充值专属账户</div>
          <div className={styles.successRow}>
            <div className={styles.successLabel}>收款银行</div>
            <div className={styles.successValue}>
              {detailData?.data?.bankDTO?.bankName}
            </div>
          </div>
          <div className={styles.successRow}>
            <div className={styles.successLabel}>收款账户名</div>
            <div className={styles.successValue}>
              {detailData?.data?.opName}
            </div>
          </div>
          <div className={styles.successRow}>
            <div className={styles.successLabel}>收款账户</div>
            <div className={styles.successValue}>
              {detailData?.data?.bankCardNo}
              <Icon
                icon="icon-fuzhi"
                className={styles.copy}
                onClick={() => copyText(detailData?.data?.bankCardNo || "")}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Check;

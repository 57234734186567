import React, { useMemo } from "react";
import { Card, Grid, Image, Space, Swiper, Tag } from "antd-mobile";

import chunk from "lodash/chunk";
import classNames from "classnames";

import type { IBannerList } from "@/types/config";

import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { ICardHeader } from "../../type";

interface ICardsProps {
  header: ICardHeader;
  iconList?: IBannerList[];
  bannerList?: IBannerList[];
  singlePage?: boolean;
}

const column = 5;
/** 卡片  */
const Cards: React.FC<ICardsProps> = (props) => {
  const { header, iconList, bannerList, singlePage } = props;
  const openLink = useOpenUrl();
  const showIconList = useMemo(() => {
    if (!iconList?.length) return [];
    return chunk(iconList, column);
  }, [iconList]);
  return (
    <Card className={styles.card} bodyClassName={styles.cardBody}>
      <Space block direction="vertical" className={styles.cardContent}>
        <Space className={styles.cardHeader}>
          {header?.title && (
            <span className={styles.title}>{header.title}</span>
          )}
          {header?.tags?.map((item, index) => (
            <Tag key={index} round className={styles.tag}>
              {item}
            </Tag>
          ))}
        </Space>
        <div className={styles.iconList}>
          {showIconList?.map((item, index) => (
            <Grid className={styles.row} columns={column} key={index}>
              {item.map((li, liIndex) => (
                <Grid.Item
                  key={liIndex}
                  className={styles.col}
                  onClick={() => {
                    li.link &&
                      openLink({
                        ...li.link,
                        url: singlePage
                          ? li.link.url.replace("/tabBar", "")
                          : li.link.url,
                      });
                  }}
                >
                  <Image
                    className={styles.icon}
                    src={li.imageUrl}
                    fit="cover"
                  />
                  <div className={styles.title}>{li.title}</div>
                </Grid.Item>
              ))}
            </Grid>
          ))}
        </div>
        <Swiper
          className={classNames(styles.bannerList, {
            [styles.hideIndicator]: bannerList?.length === 1,
          })}
        >
          {bannerList?.map((item, index) => (
            <Swiper.Item
              key={index}
              onClick={() => {
                item.link &&
                  openLink({
                    ...item.link,
                    url: singlePage
                      ? item.link.url.replace("/tabBar", "")
                      : item.link.url,
                  });
              }}
            >
              <Image className={styles.image} src={item.imageUrl} fit="cover" />
            </Swiper.Item>
          ))}
        </Swiper>
      </Space>
    </Card>
  );
};

export default Cards;

import { Avatar } from "antd-mobile";
import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

const getFeedDetail = async (params: any) => {
  const { id = 1 } = params || {};
  const res = await apiClient.get("/dmall_customer/ding/ua/feed/detail", {
    param: {
      id,
    },
  });
  const { data = [] } = res || {};

  return data;
};

export { getFeedDetail };

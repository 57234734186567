import React, { useEffect, useMemo } from "react";
import styles from "./index.module.scss";
import { Avatar, Button, List } from "antd-mobile";
import Icon from "@/common/Component/Icon";
import { RightOutline } from "antd-mobile-icons";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import usePageTitle from "@/hooks/usePageTitle";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
import { useRequest } from "ahooks";
import { AccountTypeEnum, getAccountsStatus } from "./service";
import {
  enterpriseApplyGet,
  StatusEnum,
} from "../Enterprise/services/enterpriseApplyGet";
import { getBankCard } from "../Wallet/Services";
interface IMyProps {}

/** 我的  */
const My: React.FC<IMyProps> = (props) => {
  const {} = props;
  usePageTitle({ title: "我的" });
  const { data: statusData, loading } = useRequest(getAccountsStatus);
  const [dingUserInfo, getDingUser, corpId] = useUserStore(
    useShallow((state) => [
      state.dingUserInfo,
      state.getDingUser,
      state.corpId,
      state.visibleMoney,
      state.setVisibleMoney,
    ])
  );
  const { data: listBackCard } = useRequest(getBankCard, {});

  const openUrl = useOpenUrl();
  const {
    data: applyData,
    loading: getLoading,
    run: enterpriseApplyGetRun,
  } = useRequest(enterpriseApplyGet, {
    manual: true,
  });
  const avatar =
    "https://cdn.wuhuxianmai.cn/feed/1733196886985-80e6857f7a91674dd710945b825be538541e93aa47c448c04935a64ca4395cae";

  useEffect(() => {
    getDingUser();
  }, [getDingUser]);
  useEffect(() => {
    corpId && enterpriseApplyGetRun({ applyOrgId: corpId });
  }, [corpId]);
  const enterpriseAction = useMemo(() => {
    /**
     * 餐补入驻
     */
    const action = {
      title: "餐补入驻",
      icon: "icon-qiyecanbu",
      link: {
        url: "/apply/enterprise/apply",
        isRoute: true,
      },
      onClick: () => {},
      tip: "",
    };
    switch (applyData?.data?.status) {
      case StatusEnum.PendingPlatformReview:
        action.tip = "审核中";
        break;
      case StatusEnum.ReviewFailed:
        action.tip = "审核未通过";
        break;
      case StatusEnum.ReviewPassed:
        action.tip = "已入驻";
        break;
      case StatusEnum.AccountBookOpened:
        action.tip = "已入驻";
        break;
      case StatusEnum.AccountBookBlock:
        action.tip = "已冻结";
        break;
      case StatusEnum.Cancelled:
        action.tip = "已注销";
        break;
      case StatusEnum.Void:
        action.tip = "已作废";
        break;
      default:
        action.tip = "所在组织未开通，去入驻";
    }
    return action;
  }, [applyData]);
  const { title, onClick, showAction, buttonText, actionList } = useMemo(() => {
    const actionList = [
      {
        title: "外卖订单",
        icon: "icon-waimaidingdan",
        link: {
          url: "",
          isRoute: true,
        },
        onClick: () => {},
        tip: "",
      },
    ];

    if (dingUserInfo?.userAdmin) {
      actionList.push(enterpriseAction);
    }
    const obj = {
      title: "",
      onClick: () => {},
      showAction: false,
      buttonText: "",
      actionList,
    };
    const checkCard = () => {
      openUrl({
        url: "/oneCard",
        isRoute: true,
      });
    };
    const bindCard = () => {
      /**
       * 是否绑定过企业
       */
      let hasBind = !!listBackCard?.data?.length;
      if (hasBind) {
        openUrl({
          url: "/oneCard",
          isRoute: true,
        });
      } else {
        openUrl({
          url: "/openPay",
          isRoute: true,
        });
      }
    };

    switch (statusData?.data) {
      case AccountTypeEnum.BIND:
        obj.title = "已绑定";
        obj.onClick = checkCard;
        obj.showAction = true;
        obj.buttonText = "查看";
        break;
      case AccountTypeEnum.BLOCK:
        obj.title = "已冻结";
        obj.onClick = checkCard;
        obj.showAction = true;
        obj.buttonText = "查看";
        break;
      case AccountTypeEnum.CLOSED:
        obj.title = "已注销";
        obj.onClick = checkCard;
        obj.showAction = true;
        obj.buttonText = "查看";
        break;
      case AccountTypeEnum.NO_BIND:
        obj.title = "已开通，去绑定";
        obj.onClick = bindCard;
        obj.showAction = true;
        obj.buttonText = "去绑定";
        break;
      case AccountTypeEnum.NO_ENTERPRISE:
        if (dingUserInfo?.userAdmin) {
          obj.onClick = () => {
            openUrl({
              url: "/apply/enterprise/apply",
              isRoute: true,
            });
          };
          obj.buttonText = "去开通";
          obj.showAction = true;
          obj.title = "未开通";
        } else {
          obj.title = "未开通,请联系管理员开通";
        }
        break;
      // case AccountTypeEnum.OPENED:
      //   obj.title = "已开户";
      //   break;
      default:
    }
    return obj;
  }, [statusData, dingUserInfo, enterpriseAction]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Avatar
            className={styles.avatar}
            src={dingUserInfo?.userAvatar || avatar}
          />
          <div className={styles.info}>
            <div className={styles.name}>{dingUserInfo?.userName}</div>
            <div className={styles.num}>{dingUserInfo?.userMobile}</div>
          </div>
        </div>
        <div className={styles.moneyCard}>
          <div className={styles.moneyLeft}>
            <Icon className={styles.moneyIcon} icon="icon-jinbi" />
            <span className={styles.tip}>{title}</span>
          </div>
          {showAction && (
            <Button onClick={onClick} className={styles.action}>
              {buttonText}
            </Button>
          )}
        </div>
        <div className={styles.contentCard}>
          <div className={styles.cardTitle}>功能服务</div>
          <List className={styles.list}>
            {actionList.map((item, index) => {
              return (
                <List.Item
                  arrowIcon={<RightOutline className={styles.icon} />}
                  className={styles.listItem}
                  onClick={() => {
                    openUrl(item.link);
                    item.onClick && item.onClick();
                  }}
                  key={index}
                >
                  <div className={styles.left}>
                    <Icon icon={item.icon} className={styles.leftIcon} />
                    <span>{item.title}</span>
                  </div>
                  {item.tip && <div className={styles.right}>{item.tip}</div>}
                </List.Item>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default My;

import React from "react";
import styles from "./index.module.scss";

interface IInvestmentPromotionProps {}

/** 招商宣传  */
const InvestmentPromotion: React.FC<IInvestmentPromotionProps> = (props) => {
  const {} = props;

  return <div className={styles.page} />;
};

export default InvestmentPromotion;

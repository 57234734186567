import { Toast } from "antd-mobile";
import * as dd from "dingtalk-jsapi";

/**
 * 获取用户授权码-免登
 */
export const getAuthCode = ({
  corpId,
}: {
  corpId: string;
}): Promise<{ authCode?: string; success: boolean }> => {
  return new Promise((resolve, reject) => {
    if (dd?.env?.platform === "notInDingTalk") {
      Toast.show({
        content: "请在钉钉客户端内打开",
        duration: 2000,
      });
      reject({ success: false });
      return;
    }
    dd.ready(() => {
      dd.runtime.permission
        .requestAuthCode({
          corpId, // 企业id
        })
        .then((res) => {
          resolve({ authCode: res.code, success: true });
        })
        .catch((e) => {
          reject(e);
        });
    });
  });
};

import { useRequest } from "ahooks";
import { Toast } from "antd-mobile";
import * as dd from "dingtalk-jsapi";
import { getDingConfig } from "./dingConfig";

/**
 * 钉钉JSAPI配置
 */
export const useDDJSAPIConfig = () => {
  const data = useRequest(getDingConfig, {
    onSuccess: (res) => {
      if (dd?.env?.platform !== "notInDingTalk") {
        dd.config({
          ...(res?.result || {}),
          jsApiList: ["getLocation"],
        });
        dd.error(function (error) {
          Toast.show({
            icon: "fail",
            content: JSON.stringify(error),
          });
        });
      }
    },
    defaultParams: [{ url: window.location.href }],
  });
  return data;
};

import React, { useMemo } from "react";

import { PageItem } from "@/common/Component/Page";
import Cards from "./components/Cards";
import Header from "./components/Header";
import Banner from "./components/Banner";

import styles from "./index.module.scss";
import DiverseCard from "./components/DiverseCard";
import Information from "./components/Information";
import usePageTitle from "@/hooks/usePageTitle";
import { useConfigJSON } from "@/hooks/useConfigJSON";
import { ComponseTypeEnum, IConfig } from "./type";
import { SpinLoading } from "antd-mobile";
import { useRequest } from "ahooks";
import { getFeedBanner } from "./services/feedBanner";
import useGlobalStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
interface ILocalLifeProps {
  /** 是否隐藏我的 */
  hideMy?: boolean;
  /** 是否单页模式 */
  singlePage?: boolean;
}

/** 本地生活  */
const LocalLife: React.FC<ILocalLifeProps> = (props) => {
  const { hideMy, singlePage } = props;
  usePageTitle({ title: "首页" });
  const [appType] = useGlobalStore(useShallow((state) => [state.appType]));
  const configId = useMemo(() => {
    const configObj: any = {
      params: {},
      options: { manual: true },
    };
    if (!appType) {
      return configObj;
    }
    if (appType === "app") {
      configObj.params.id = "1859477667784527875";
    }
    if (appType === "h5") {
      configObj.params.id = "1859477667784527874";
    }
    return configObj;
  }, [appType]);
  const { config, loading } = useConfigJSON<IConfig>(configId);
  const { data } = useRequest(getFeedBanner);

  if (loading) {
    return (
      <div className={styles.loading}>
        <SpinLoading />
      </div>
    );
  }
  return (
    <div style={{ width: "100%" }}>
      {config?.map((item, index) => {
        switch (item.type) {
          case ComponseTypeEnum.Header:
            return <Header hideMy={hideMy} data={item} key={index} />;
          case ComponseTypeEnum.Banner:
            return (
              <Banner key={index} bannerList={data?.data ? [data.data] : []} />
            );
          case ComponseTypeEnum.Card:
            return (
              <PageItem key={index} className={styles.pageItem}>
                <Cards {...item} singlePage={singlePage} />
              </PageItem>
            );
          case ComponseTypeEnum.DiverseCard:
            return (
              <PageItem key={index} className={styles.pageItem}>
                <DiverseCard {...item} />
              </PageItem>
            );
          case ComponseTypeEnum.Information:
            return (
              <PageItem key={index} className={styles.pageItem}>
                <Information {...item} />
              </PageItem>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default LocalLife;

import useUserStore from "@/store/global";
import { useEffect } from "react";
import qs from "qs";

export const useSetCorpId = () => {
  const { setCorpId } = useUserStore();

  useEffect(() => {
    const searchParams = qs.parse(window.location.search.slice(1)) as any;
    if (searchParams?.corpId) {
      setCorpId(searchParams?.corpId);
    }
  }, []);
};

const HOST = "https://h5.wuhuxianmai.cn";
const STATIC_HOST = "https://cdn.wuhuxianmai.cn/images";

const BANK_HOST = "https://cdn.wuhuxianmai.cn/bank";

const API_HOST = "http://47.97.4.90:9999";

const ELEME_HOST: { [key: string]: string } = {
  "001":
    "https://h5.ele.me/2021002182632481/?activityId=11980387&title=%E7%BE%8E%E9%A3%9F%E5%A4%96%E5%8D%96&title_bar_image=eb7f827912d913730a263dc418db0018png",

  "002":
    "https://h5.ele.me/2021002182632481/?activityId=11980435&title=%E7%94%9C%E5%93%81%E9%A5%AE%E5%93%81&title_bar_image=3513e7f3a6e677e2aa2d02c3676afa52png",

  "003":
    "https://h5.ele.me/2021002182632481/?activityId=11980491&title=%E6%B1%89%E5%A0%A1%E6%8A%AB%E8%90%A8&title_bar_image=286cc7d89bb2d1006d76f1a6c7be8aedpng",

  "004":
    "https://h5.ele.me/2021002182632481/?activityId=11980499&title=%E5%BF%AB%E9%A4%90%E4%BE%BF%E5%BD%93&title_bar_image=7efb80f5178993903b98984aad73b3f3png",

  "005":
    "https://h5.ele.me/2021002182632481/?activityId=11980467&title=%E4%B8%8B%E5%8D%88%E8%8C%B6&title_bar_image=5dbae9cd215f5541ba75adaf40b13f30png",

  "006": "https://h5.ele.me/newretail/p/emart/?tmp=1",

  "007": "https://h5.ele.me/newretail/p/efresh/?tmp=1",

  "008": "https://tb.ele.me/app/elenr/health/index?hide_title=1",

  "009": "https://h5.ele.me/newretail/p/ekitchen/?tmp=1",

  "010": "https://h5.ele.me/newretail/p/eflower/?tmp=1",

  "011":
    "https://h5.ele.me/2021001185671035/pages/ele-takeout-index/ele-takeout-index?from=mobile.taobao",

  "012":
    "https://h5.ele.me/newretail/p/ushop/?store_id=1096136200&ele_id=1166595554&geolat=22.924089&geolng=113.368681&fetchType=0&venusSupplyCategoryType=storecard&__allocated_large_heap=true&venusSupplyChannelSource=fresh&index=1&rankId=ws0d7v04E0C54BD5F414AF8A157974528C9B158%7C1727404245777_ad958e79810cd970d2ba66e32f5e6a97172843828&channel=fresh&supportShowInteractCard=false&spm=a2ogi.12532489.category-shopcard.d1&*from=mobile.whrongmeiti&from=mobile.whrongmeiti&*eretail-passon=&welfare_3pp=WHRMT_PAY&opensite_source=whrongmeiti",
  /** 到店-便利生活-精选外卖 */
  "013":
    "https://h5.ele.me/2021002182632481/?activityId=11980387&title=%E7%BE%8E%E9%A3%9F%E5%A4%96%E5%8D%96&title_bar_image=eb7f827912d913730a263dc418db0018png&title_bar_image_width=84&theme_color=009fff&theme_mid_color=009fff&theme_type=1&selected_color=009fff&category_id=385835&contentMarkInfo=%7B%22entryName%22%3A%22%E7%BE%8E%E9%A3%9F%E5%A4%96%E5%8D%96%22%7D&headerType=4",
  /** 到店-便利生活-便利超市 */
  "014": "https://h5.ele.me/newretail/p/emart",
  /** 到店-便利生活-水果蔬菜 */
  "015": "https://h5.ele.me/newretail/p/efreshmarket/?hideNavbar=1",
  /** 到店-便利生活-看病买药 */
  "016": "https://h5.ele.me/newretail/p/ehealth-rax-qrcode/?from=m_site",
  /** 到店-便利生活-banner */
  "017":
    "https://h5.ele.me/adminiappsub/pages/h5/index?configKey=BDLM_ELE_H5_DG_TC&scene=0c26dfdd3d0e4fe1b291e77285c4a1f7&configKey=BDLM_ELE_H5_DG_TC",
};

export { HOST, STATIC_HOST, API_HOST, BANK_HOST, ELEME_HOST };

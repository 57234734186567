import React, { useCallback, useEffect, useState } from "react";
import { Button, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import { useSearchParams } from "react-router-dom";
import Header from "./Component/Header";
import Content from "./Component/Content";
// import PayPwd from "./Component/PayPwd";
import PageLoading from "src/common/Component/PageLoading";
import StorageService from "src/common/Utils/store";
import VerificationCode from "src/common/Component/VerificationCode";
import { getBuyerPay } from "./Services";
import styles from "./index.module.scss";
import { confirmPay } from "./Services";
import usePageTitle from "@/hooks/usePageTitle";


function Pay() {
  const [params] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const [payInfo, setPayInfo] = useState<any>({});
  const [coupon, setCoupon] = useState([]);
  const payOrderId = params.get("payOrderId");

  usePageTitle({ title: "芜湖生活支付" });

  const { data, loading } = useRequest(
    getBuyerPay({
      payOrderId: payOrderId,
    })
  );

  const { run } = useRequest(confirmPay, {
    manual: true,
    onSuccess: (res: any) => {
      const { success, msg, data } = res || {};
      if (success) {
        if (payInfo.payMethodCd === 'alipay' || payInfo.payMethodCd === 'wechat') {
          window.location.href = data + encodeURIComponent("&token=" + StorageService.shared.getToken());
        } else {
          setTimeout(() => {
            window.location.href = data;
          }, 2000);
        }
      } else {
        Toast.show({
          icon: "fail",
          content: msg || "支付失败，请稍后再试!",
        });
      }
    },
    onError: (err: any) => {
      const { msg } = err || {};
      Toast.show({
        icon: "fail",
        content: msg || "支付失败，请稍后再试!",
      });
    },
  });

  const onChangePayInfo = useCallback((type: any) => {
    setPayInfo(type);
  }, []);

  const onChangeCoupon = useCallback((val: any) => {
    setCoupon(val);
  }, []);

  const onConfirm = useCallback(() => {
    if (!payInfo || !payInfo?.payMethodId) {
      Toast.show({
        content: "请选择支付方式",
      });
      return;
    }

    if(payInfo.payMethodCd === 'alipay' || payInfo.payMethodCd === 'wechat') {
      run({
        payOrderId: payOrderId,
        payMethodId: payInfo.payMethodId,
      });

      return;
    }
    setVisible(true);
  }, [payInfo]);

  const okCode = useCallback((code: string) => {
    run({
      payOrderId: payOrderId,
      payMethodId: payInfo.payMethodId,
      bankCardNo: payInfo.bankCardNo,
      payPassword: code,
    });
  }, [payInfo]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className={styles.app}>
      <Header data={data} payInfo={payInfo} />
      <Content
        coupon={coupon}
        payInfo={payInfo}
        data={data}
        onChangeCoupon={onChangeCoupon}
        onChangePayInfo={onChangePayInfo}
      />
      <div className={styles.footer}>
        <Button className={styles.footerBtn} onClick={onConfirm} block color="primary" size="large">
          确认支付
        </Button>
      </div>
      {/* <PayPwd
        onClose={onClose}
        visible={visible}
        data={data}
        params={{
          ...payInfo,
          payOrderId: params.get("payOrderId"),
        }}
      /> */}
      <VerificationCode
        visible={visible}
        phone={payInfo.phone}
        isCheckAfter={true}
        onClose={() => {
          setVisible(false);
        }}
        onOk={okCode}
      />
    </div>
  );
}

export default Pay;

import React from "react";
import { Image, NavBar, Ellipsis} from 'antd-mobile'
import { useSearchParams } from "react-router-dom";
import { STATIC_HOST } from "src/common/Config/Constant";

import style from "./index.module.scss";

function FeedDetail() {
  
  const left = () => {
    return (
      <div className={style.icon}>
        <img src={`${STATIC_HOST}/logo2.png`} alt="" />
        <span>芜湖本地生活</span>
      </div>
    )
  }

  return (
    <div className={style.app}>
      <NavBar 
        back={null}
        left={left()}
      />
      <Image src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/809246848.png" fit='cover' />
      <div className={style.title}>
        <Ellipsis direction='end' rows={2} content="芜湖本地生活上线啦!快来看看!!" />
      </div>
      <div className={style.content}>
      本地生活是基于钉钉打造的一站式城市生活服务平台，涵盖本地生活服务、餐饮美食、生活用品、娱乐休闲等多个领域，助力本地商户快速拓展业务，满足企业员工多方位便捷与优惠需求，推动地方文旅宣传，为消费升级保驾护航。
      </div>
      <div className={style.bottomBanner}>
        <Image src="https://citycdn.oss-cn-hangzhou.aliyuncs.com/images/683131509.png" fit='cover' />
      </div>
    </div>
  );
}

export default FeedDetail;
import React, { CSSProperties, useMemo } from "react";
import { useRequest } from "ahooks";
import get from "lodash/get";

import { useConfigJSON } from "src/hooks/useConfigJSON";
import { IActivityCardConfig, IConfig } from "./config";
import Page, { PageItem } from "src/common/Component/Page";
import { Image, Space } from "antd-mobile";
import usePageTitle from "src/hooks/usePageTitle";

import ToTop from "src/common/Component/ToTop";

import ActivityCard from "../../components/ActivityCard";
import { getFavoritesId } from "../../service/getFavoritesId";
import styles from "./index.module.scss";

interface IActivityListProps {}

/** 活动列表  */
const ActivityList: React.FC<IActivityListProps> = (props) => {
  const {} = props;
  const { config } = useConfigJSON<IConfig>({
    options: { manual: true, searchParamsName: ["id"] },
  });
  usePageTitle({ title: config?.pageTitle });

  const { data: favoritesList, loading } = useRequest(getFavoritesId);

  const pageStyle: CSSProperties = {
    backgroundColor: config?.backgroundColor,
  };
  const showList = useMemo(() => {
    const list = get(
      favoritesList,
      "tbk_dg_material_recommend_response.result_list.map_data.0.favorites_info.favorites_list.favorites_detail",
      []
    );
    const showActivity: IActivityCardConfig[] = [];
    config?.activityCardConfig?.forEach((item) => {
      const data = list.find((i) => i.favorites_id === item.activityId);
      if (data) {
        showActivity.push({
          ...item,
          title: item?.title || data.favorites_title,
        });
      }
    });
    return showActivity;
  }, [config, favoritesList]);

  return (
    <Page className={styles.page} style={pageStyle}>
      <Image fit="cover" src={config?.backgroundImageUrl} width={"100%"} />
      <PageItem className={styles.content}>
        <Space block className={styles.activitySpace} direction="vertical">
          {showList.map((item, index) => {
            return <ActivityCard data={item} key={index} />;
          })}
        </Space>
      </PageItem>
      <ToTop />
    </Page>
  );
};

export default ActivityList;

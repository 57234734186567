import { Decimal } from "decimal.js";

/**
 * 格式化金额，保留两位小数，并使用千位分隔符
 * @param value 待格式化的金额值
 * @param hasFen 是否需要将分转换为元
 * @returns 格式化后的金额字符串(132,123.00) 或 undefined
 */

export function formatCurrency(
  value?: string | number,
  hasFen: boolean = true
): string | undefined {
  if (value === undefined) {
    return undefined;
  }

  const num = new Decimal(value);

  if (num.isNaN()) {
    throw new Error("value 不是有效数字");
  }

  // 根据 hasFen 参数决定是否将分转换为元
  const formattedNum = hasFen ? num.div(100) : num;

  // 格式化数字为带有两位小数的字符串
  let formatted = formattedNum.toDecimalPlaces(2).toString();

  // 确保返回的字符串带有两位小数
  if (!formatted.includes(".")) {
    formatted += ".00";
  } else if (formatted.split(".")[1].length < 2) {
    formatted += "0".repeat(2 - formatted.split(".")[1].length);
  }

  // 使用逗号作为千位分隔符
  return formatted.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

import axios from "src/common/Utils/APIClient";
export interface IAccountBindReq {
  /**
   * 姓名
   */
  opName: string;
  /**
   * 身份证号
   */
  opCert: string;
  /**
   * 手机号
   */
  opPhone: string;
  /**
   * 银行卡号
   */
  bankCardNo?: string;
  /**
   * 绑定组织id
   */
  orgId?: string;
  /**
   * 验证码
   */
  payPassword: string;
}
export interface IAccountBindRes {
  code: string;
  msg: string;
  data: boolean;
  success: boolean;
}

/**
 * 绑定账户
 * @param data
 * @returns
 */
export const accountBind = (
  data: IAccountBindReq
): Promise<IAccountBindRes> => {
  return axios.post("/dmall_account/Account/bind", data);
};

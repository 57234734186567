import React, { useEffect, useState } from "react";
import { Modal } from 'antd-mobile';
import CountUp from 'react-countup';
import { regFenToYuan } from "src/common/Utils";
import styles from "./index.module.scss";

let lastFee = 0;

function Header(props: any) {
  const { data, payInfo } = props || {};
  const { payAmount = 0, timeoutSeconds } = data || {};
  const currentFeeAmount = payInfo?.feeAmount || 0;
  const currentFee = payAmount + currentFeeAmount;
  const [timeLeft, setTimeLeft] = useState<number>(0);


  useEffect(() => {
    lastFee = currentFee;
  }, [currentFee]);

  useEffect(() => {
    if (!timeoutSeconds) return;

    const startCountTime = Math.floor(Date.now() / 1000);
    
    const endTime = new Date(timeoutSeconds).getTime();
    const now = new Date().getTime();
    const initialTimeLeft = Math.max(0, Math.floor((endTime - now) / 1000));
    
    setTimeLeft(initialTimeLeft);
    
    const timer = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const remainingTime = timeoutSeconds - (now - startCountTime);

      if (remainingTime <= 0) {
        clearInterval(timer);
        Modal.show({
          title: '订单已超时',
          content: '订单已取消，请重新下单',
          closeOnMaskClick: false,
          closeOnAction: false,
          actions: [
            {
              key: 'know',
              text: '知道了',
              primary: true,
              onClick: () => {
                window.location.href = '/tarBar';
              }
            }
          ],
        });
      } else {
        setTimeLeft(remainingTime);
      }
    }, 1000);
  }, [timeoutSeconds]);
  
  return (
    <ul className={styles.header}>
      <li className={styles.sub}>实付金额</li>
      <li className={styles.amount}>
        <span className={styles.symbol}>¥</span><CountUp
          className={styles.count}
          start={regFenToYuan(lastFee)}
          end={regFenToYuan(currentFee)}
          duration={0.8}
          decimals={2}
        ></CountUp>
      </li>
      <li className={styles.time}>
        剩余支付时间 {Math.floor(timeLeft / 60)}:{String(timeLeft % 60).padStart(2, '0')}
      </li>
    </ul>
  );
}

export default Header;

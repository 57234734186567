import React, { useCallback, useEffect, useRef, useState } from "react";
import { PasscodeInput, Popup, Toast } from "antd-mobile";
import styles from "./index.module.scss";
import Buttons from "@/common/Component/Button";
import { useRequest } from "ahooks";
import { getVerificationCode } from "@/services/auth/getVerificationCode";
import { checkVerificationCode } from "@/services/auth/checkVerificationCode";

interface IVerificationCodeProps {
  visible?: boolean;
  phone?: string;
  onClose?: () => void;
  onOk?: (code: string) => void;
  isCheckAfter?: boolean;
}

/** 验证码  */
const VerificationCode: React.FC<IVerificationCodeProps> = (props) => {
  const { visible, onClose, phone, onOk, isCheckAfter = false } = props;
  const inputRef = useRef<{ blur: () => void; focus: () => void }>(null);
  const [error, setError] = useState(false);
  const timeOut = useRef<ReturnType<typeof setInterval>>();
  const { loading, run } = useRequest(getVerificationCode, {
    manual: true,
    throttleWait: 300,
    onBefore: () => {
      let initTime = 60;
      timeOut.current = setInterval(() => {
        if (initTime >= 0) {
          setSec(initTime);
          initTime--;
        }
        if (initTime < 0) {
          clearInterval(timeOut.current);
          timeOut.current = undefined;
        }
      }, 1000);
    },
  });

  const { run: checkRun, loading: checkLoading } = useRequest(
    checkVerificationCode,
    {
      manual: true,
      onSuccess: (res, params) => {
        if (res.data && res.success) {
          if (!isCheckAfter) {
            Toast.show({
              content: "验证成功",
              duration: 2000,
            });
          }
          onClose && onClose();

          onOk && onOk(params[0].code);
        } else {
          inputRef.current?.focus();
          setError(true);
        }
      },
      onError: () => {
        inputRef.current?.focus();
        setError(true);
      },
    }
  );
  const [sec, setSec] = useState<number>();

  const getCode = useCallback(() => {
    if (!visible) return;
    if (!phone) {
      Toast.show({
        content: "请输入手机号",
      });

      return;
    }
    if (visible && !timeOut.current) {
      run({ phone });
    }
  }, [visible, phone, run]);
  useEffect(() => {
    getCode();
  }, [getCode]);

  const onFill = (val: string) => {
    if (phone && val?.length === 6) {
      checkRun({ code: val, phone });
    }
  };
  return (
    <Popup
      showCloseButton
      bodyClassName={styles.moduleBody}
      className={styles.module}
      visible={visible}
      onClose={onClose}
      afterShow={() => {
        inputRef.current?.focus();
      }}
      destroyOnClose={false}
    >
      <div className={styles.title}>请输入验证码</div>
      <div className={styles.subTitle}>
        <span className={styles.label}>验证码已发送至</span>
        <span className={styles.phone}>+86 {phone}</span>
      </div>
      <PasscodeInput
        onFill={onFill}
        onFocus={() => {
          setError(false);
        }}
        className={styles.passcode}
        plain
        seperated
        ref={inputRef}
        error={error}
      />
      <div className={styles.passcodeFooter}>
        {!sec && (
          <Buttons
            onClick={() => {
              getCode();
              setError(false);
              inputRef.current?.focus();
            }}
            color="primary"
            fill="none"
            loading={loading}
          >
            发送验证码
          </Buttons>
        )}
        {!!sec && (
          <span className={styles.timer}>
            重新发送<span className={styles.timerNum}>{sec}s</span>
          </span>
        )}
      </div>
    </Popup>
  );
};

export default VerificationCode;

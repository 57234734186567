import { getAuthCode } from "@/services/dd/getAuthCode";
import { getUserInfo, UserInfoData } from "@/services/user/getUserInfo";
import { getDingUser, UserDingInfoData } from "@/services/user/getDingUser";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Toast } from "antd-mobile";
import {
  openAuthDialog,
  userDingDingAuthFieldScope,
  userDingDingAuthRpcScope,
} from "@/services/dd/openAuth";
import { userDingConfirm } from "@/services/user/userDingConfirm";
import qs from "qs";
type AppType = "h5" | "app";

interface UserState {
  dingdingUserInfo?: UserInfoData;
  dingUserInfo?: UserDingInfoData;
  /**
   * 用户组织id
   */
  corpId?: string;

  getDingDingUserInfo: (data: {
    corpId: string;
  }) => Promise<UserInfoData | undefined>;
  getDingUser: () => Promise<UserDingInfoData | undefined>;
  setCorpId: (corpId: string) => void;
  /**
   * 应用
   */
  appType?: AppType;
  setAppType: () => void;
  visibleMoney: boolean;
  setVisibleMoney: (v: boolean) => void;
  clientId?: string;
  setClientId: () => void;
}
const getAuthCodeFC = async (corpId: string) => {
  let userInfo: UserDingInfoData | undefined = undefined;

  const authCodeObj = await getAuthCode({ corpId }).catch((e) => {
    return { authCode: undefined };
  });
  if (authCodeObj?.authCode) {
    userInfo = await getDingUser({
      authCode: authCodeObj?.authCode,
      corpId: corpId,
    }).catch((e) => {
      return undefined;
    });
  }
  return userInfo;
};
const useUserStore = create<UserState>()(
  persist(
    (set, get) => ({
      dingdingUserInfo: undefined,
      corpId: undefined,
      getDingDingUserInfo: async (data: {
        corpId: string;
      }): Promise<UserInfoData | undefined> => {
        const authCodeObj = await getAuthCode(data).catch((e) => {
          return { authCode: undefined };
        });
        let userInfo: UserInfoData | undefined = undefined;
        if (authCodeObj?.authCode) {
          userInfo = await getUserInfo({
            authCode: authCodeObj?.authCode,
            corpId: data.corpId,
          }).catch((e) => {
            console.log("e2", e);
            return undefined;
          });
        }
        set((state) => ({
          dingdingUserInfo: userInfo,
        }));
        return userInfo;
      },
      setCorpId: (corpId: string) => set(() => ({ corpId })),
      getDingUser: async () => {
        console.log("getDingUser", get().clientId, get().corpId);
        const { clientId, corpId } = get();
        if (corpId && clientId) {
          let userInfo: UserDingInfoData | undefined = undefined;
          userInfo = await getAuthCodeFC(corpId);
          if (!userInfo?.isGrant && userInfo?.unionId) {
            const openAuthData = await openAuthDialog({
              corpId,
              type: 0,
              rpcScope: userDingDingAuthRpcScope.ContactUserRead,
              fieldScope: userDingDingAuthFieldScope.ContactUserMobile,
              clientId,
            });
            console.log("openAuth", openAuthData);
            if (openAuthData.success && openAuthData.authCode) {
              const confirm = await userDingConfirm({
                authCode: openAuthData.authCode,
                corpId,
                unionId: userInfo?.unionId,
              });
              console.log("confirm", confirm);
              if (confirm.success) {
                userInfo = await getAuthCodeFC(corpId);
                console.log("userInfo2", userInfo);
              }
            } else {
              Toast.show({
                icon: "fail",
                content: openAuthData.message || "授权失败",
              });
            }
          }
          console.log("userInfo3", typeof userInfo);
          set((state) => ({
            dingUserInfo: userInfo,
          }));
          return userInfo;
        } else {
          Toast.show({
            content: "钉钉组织id 不存在",
            duration: 2000,
          });
          return undefined;
        }
      },
      setAppType: () => {
        const hostname = window.location.hostname;
        switch (hostname) {
          case "localhost":
            set(() => ({ appType: "h5" }));
            break;
          case "app.wuhuxianmai.cn":
            set(() => ({ appType: "app" }));
            break;
          case "h5.wuhuxianmai.cn":
            set(() => ({ appType: "h5" }));
            break;
          default:
            set(() => ({ appType: undefined }));
        }
      },
      visibleMoney: true,
      setVisibleMoney: (v: boolean) => {
        const visibleMoney = get().visibleMoney;
        if (v === visibleMoney) {
          return;
        }
        set((state) => ({
          visibleMoney: v,
        }));
      },
      setClientId: () => {
        const clientId = qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })?.clientId as string | undefined;
        clientId && set(() => ({ clientId }));
      },
    }),
    {
      name: "bear-storage", // 存储中的项目名称，必须是唯一的
      storage: createJSONStorage(() => localStorage), // 使用localStorage作为存储
      partialize: (state) => ({
        // 仅存储特定的字段
        corpId: state.corpId,
        visibleMoney: state.visibleMoney,
        clientId: state.clientId,
      }),
    }
  )
);

export default useUserStore;

// 基准大小
const BASE_SIZE = 75;

// 设置 rem 函数
function changeHtmlSize() {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 750;
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize =
    BASE_SIZE * Math.min(scale, 2) + "px";
}

export default changeHtmlSize;

/**
 * 将像素值转换为 rem 单位
 * @param px 像素值
 * @returns 对应的 rem 值
 */
export function pxToRem(px: number): string {
  return (px / BASE_SIZE).toFixed(2) + "rem";
}

import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
interface DataItem {
  title?: string;
}
interface IStepProps {
  current?: number;
  dataSource?: DataItem[];
}

/** 步骤  */
const Step: React.FC<IStepProps> = (props) => {
  const { current = 0, dataSource } = props;

  return (
    <div className={styles.step}>
      {dataSource?.map((item, index) => {
        return <StepItem data={item} key={index} active={current >= index} />;
      })}
    </div>
  );
};

interface IStepItemProps {
  active?: boolean;
  data: DataItem;
}

const StepItem: React.FC<IStepItemProps> = (props) => {
  const { active, data } = props;
  return (
    <div className={classNames(styles.stepItem, { [styles.active]: active })}>
      <div className={styles.lineBox}>
        <div className={styles.line} />
        <div className={styles.point} />
        <div className={styles.line} />
      </div>
      <div className={styles.content}>{data.title}</div>
    </div>
  );
};

export default Step;

import apiClient from "src/common/Utils/APIClient";

const bindEleme = async (parmas: any) => {
  const { elemeUrl = '' } = parmas || {};
  const res = await apiClient.get("/dmall_customer/ding/elm/bind", {
    param: {
      elemeUrl: encodeURIComponent(elemeUrl)
    }
  });
    
  return res;
};

export { bindEleme };

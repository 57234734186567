import axios, { AxiosResponse } from "axios";
import StorageService from "./store";
import { Toast } from "antd-mobile";
export interface IBaseRes<T> {
  code?: string;
  data?: T;
  msg?: string;
  success?: boolean;
}
export class APIClientConfig {
  private _apiURL: string = "https://admin.wuhuxianmai.cn/gw";
  private _token: string = "";
  tokenCallback?: () => string | undefined;
  // private _apiURL: string = "/api/v1/" // 正式打包用此地址

  set apiURL(apiURL: string) {
    this._apiURL = apiURL;
    axios.defaults.baseURL = apiURL;
  }
  get apiURL(): string {
    return this._apiURL;
  }
}

class APIClient {
  private constructor() {
    this.initAxios();
  }
  public static shared = new APIClient();
  public config = new APIClientConfig();
  public logoutCallback?: () => void;

  initAxios() {
    const self = this;
    axios.interceptors.request.use(function (config) {
      let token: string | undefined;
      if (self.config.tokenCallback) {
        token = self.config.tokenCallback();
      }
      if (token && token !== "") {
        // config.headers!["city_token"] = token;
        // config.headers!["Authorization"] = `Bearer ${token}`;
        config.headers!["Authorization"] = token;
      }
      return config;
    });

    axios.interceptors.response.use(
      function (response) {
        const { data = {} } = response || {};
        const { code = "" } = data || {};

        if (code.toString() === "A00004" && self.logoutCallback) {
          self.logoutCallback();
          return response;
        }
        const content =
          typeof response?.data?.data === "string"
            ? response.data.data
            : response.data.msg;
        if (response.data.success === false && content) {
          Toast.show({
            icon: "fail",
            content,
          });
        }
        return response;
      },
      function (error) {
        var msg = "";
        switch (error.response && error.response.status) {
          case 400:
            msg =
              typeof error?.response?.data?.data === "string"
                ? error.response.data.data
                : error.response.data.msg;
            break;
          case 404:
            msg = "请求地址没有找到（404）";
            break;
          case 401:
            if (self.logoutCallback) {
              self.logoutCallback();
            }
            break;
          default:
            // msg = "未知错误";
            break;
        }
        msg &&
          Toast.show({
            icon: "fail",
            content: msg,
          });
        return Promise.reject({
          error: error,
          msg: msg,
          status: error?.response?.status,
        });
      }
    );
  }

  get<T>(path: string, config?: RequestConfig) {
    return this.wrapResult<T>(
      axios.get(path, {
        params: config?.param,
      }),
      config
    );
  }
  post<T>(path: string, data?: any, config?: RequestConfig) {
    return this.wrapResult<T>(axios.post(path, data, {}), config);
  }

  put<T>(path: string, data?: any, config?: RequestConfig) {
    return this.wrapResult<T>(
      axios.put(path, data, {
        params: config?.param,
      }),
      config
    );
  }

  delete<T>(path: string, config?: RequestConfig) {
    return this.wrapResult<T>(
      axios.delete(path, {
        params: config?.param,
        data: config?.data,
      }),
      config
    );
  }

  private async wrapResult<T = APIResp>(
    result: Promise<AxiosResponse>,
    config?: RequestConfig
  ): Promise<T | any> {
    if (!result) {
      return Promise.reject();
    }

    return result.then((value) => {
      if (!config || !config.resp) {
        return Promise.resolve(value.data);
      }
      if (value.data) {
        const results = new Array<T>();
        if (value.data instanceof Array) {
          for (const data of value.data) {
            var resp = config.resp();
            resp.fill(data);
            results.push(resp as unknown as T);
          }
          return results;
        } else {
          var sresp = config.resp();
          sresp.fill(value.data);
          return Promise.resolve(sresp);
        }
      }
      return Promise.resolve();
    });
  }
}

// APIClient.shared.config.apiURL = "https://admin.wuhuxianmai.cn";
APIClient.shared.config.apiURL =
  process.env.REACT_APP_API_URL || "https://admin.wuhuxianmai.cn/gw";
APIClient.shared.config.tokenCallback = (): string => {
  return StorageService.shared.getToken();
};
APIClient.shared.logoutCallback = (): void => {
  window.location.href = `/login?route=${encodeURIComponent(
    window.location.pathname.substring(1)
  )}`;
};

export class RequestConfig {
  param?: any;
  data?: any;
  headers?: any;
  resp?: () => APIResp;
}

export interface APIResp {
  fill(data: any): void;
}

export default APIClient.shared;

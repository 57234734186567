import React, { useEffect, useMemo, useRef, useState } from "react";

import { Empty, Grid, Image, InfiniteScroll, SpinLoading } from "antd-mobile";
import { useRequest } from "ahooks";
import get from "lodash/get";
import type { IInformationHeader } from "../../type";
import styles from "./index.module.scss";
import InfoCard from "../InfoCard";
import { getFeedInfoPage, Result } from "../../services/fdFeedInfo";
import data from "@/pages/OrderHistory/data";

interface IInformationProps {
  header?: IInformationHeader;
  params?: any;
}
const pageSize = 10;
/** 资讯  */
const Information: React.FC<IInformationProps> = (props) => {
  const { header, params } = props;

  const pageNum = useRef(0);
  const [pageAllData, setPageAllData] = useState<Result[]>([]);
  const {
    data: currentPageData,
    run: getPageRun,
    loading,
  } = useRequest(getFeedInfoPage, {
    manual: true,
    onSuccess: (data, params) => {
      pageNum.current = params[0].page || 0;
      setPageAllData([...pageAllData, ...(data?.data?.result || [])]);
      return data;
    },
  });
  const showData = useMemo(() => {
    const data: Array<typeof pageAllData> = [[], []];
    pageAllData.forEach((item, index) => {
      data[index % 2].push(item);
    });
    return data;
  }, [pageAllData]);
  const hasMore = useMemo(() => {
    return currentPageData?.data?.hasMore || false;
  }, [currentPageData]);
  async function loadMore() {
    if (loading) {
      return;
    }

    getPageRun({
      page: pageNum.current + 1,
      size: pageSize,
      bizType: "",
    });
  }
  useEffect(() => {
    getPageRun({ page: 1, size: pageSize, bizType: "" });
  }, []);
  return (
    <div>
      <div className={styles.header}>
        {!!header?.imageUrl && (
          <Image
            className={styles.imageUrl}
            src={header?.imageUrl}
            fit="cover"
          />
        )}
        {!!header?.title && <div className={styles.title}>{header?.title}</div>}
      </div>
      {!loading && !pageAllData.length && <Empty description="暂无数据" />}
      {loading && !pageAllData.length && (
        <div className={styles.loading}>
          <SpinLoading />
        </div>
      )}
      {!!pageAllData?.length && (
        <>
          <Grid columns={2} className={styles.productGrid}>
            {showData.map((item, index) => {
              return (
                <Grid.Item key={index}>
                  <Grid columns={1} className={styles.productGridIn}>
                    {item.map((li, iIndex: number) => {
                      return (
                        <Grid.Item key={iIndex}>
                          <InfoCard data={li} />
                        </Grid.Item>
                      );
                    })}
                  </Grid>
                </Grid.Item>
              );
            })}
          </Grid>
          <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
        </>
      )}
    </div>
  );
};

export default Information;

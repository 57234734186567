// import { HOST } from "../Config/Constant";

const objectToQueryString = (obj: any): string => {
  if (!obj || Object.keys(obj).length === 0) {
    return "";
  }

  return Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
};

const redirect = (path: string, params?: any): void => {
  if (!path) {
    console.error("--请输入正确的跳转路径--", path);
    return;
  }

  if (path.indexOf("http://") === 0 || path.indexOf("https://") === 0) {
    window.location.href = `${path}?${objectToQueryString(params)}`;
    return;
  }
  if (objectToQueryString(params).length > 0) {
    window.location.href = `${path}?${objectToQueryString(params)}`;
  } else {
    window.location.href = `${path}`;
  }
};

export const isNumber = (value: any): boolean => {
  return /^[+-]?\d+(\.\d+)?$/.test(value);
};

export const toDecimal2 = (x: any) => {
  if (isNaN(parseFloat(x))) {
    return false;
  }
  const f = Math.round(x * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf(".");

  if (rs < 0) {
    rs = s.length;
    s += ".";
  }

  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
};

export const regYuanToFen = (yuan: number, digit: number) => {
  if (!isNumber(yuan)) {
    return 0;
  }

  var m = 0,
    s1 = yuan.toString(),
    s2 = digit.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
};

export const regFenToYuan = (fen: any) => {
  if (!isNumber(fen)) {
    return 0;
  }

  let num = fen;
  num = fen * 0.01;
  num += "";
  const reg =
    num.indexOf(".") > -1
      ? /(\d{1,3})(?=(?:\d{3})+\.)/g
      : /(\d{1,3})(?=(?:\d{3})+$)/g;
  num = num.replace(reg, "$1");
  num = toDecimal2(num);
  return num;
};

export const priceToThousands = (num: any) => {
  if (num) {
    num = toDecimal2(num);
    //将num中的$,去掉，将num变成一个纯粹的数据格式字符串
    num = num.toString().replace(/\$|\,/g, "");
    //如果num不是数字，则将num置0，并返回
    if ("" === num || isNaN(num)) {
      return "Not a Number ! ";
    }
    //如果num是负数，则获取她的符号
    const sign = num.indexOf("-") > 0 ? "-" : "";
    //如果存在小数点，则获取数字的小数部分
    let cents = num.indexOf(".") > 0 ? num.substr(num.indexOf(".")) : "";
    cents = cents.length > 1 ? cents : ""; //注意：这里如果是使用change方法不断的调用，小数是输入不了的
    //获取数字的整数数部分
    num = num.indexOf(".") > 0 ? num.substring(0, num.indexOf(".")) : num;
    //如果没有小数点，整数部分不能以0开头
    if ("" === cents) {
      if (num.length > 1 && "0" === num.substr(0, 1)) {
        return "Not a Number ! ";
      }
    }
    //如果有小数点，且整数的部分的长度大于1，则整数部分不能以0开头
    else {
      if (num.length > 1 && "0" === num.substr(0, 1)) {
        return "Not a Number ! ";
      }
    }
    //针对整数部分进行格式化处理，这是此方法的核心，也是稍难理解的一个地方，逆向的来思考或者采用简单的事例来实现就容易多了
    /*
        也可以这样想象，现在有一串数字字符串在你面前，如果让你给他家千分位的逗号的话，你是怎么来思考和操作的?
        字符串长度为0/1/2/3时都不用添加
        字符串长度大于3的时候，从右往左数，有三位字符就加一个逗号，然后继续往前数，直到不到往前数少于三位字符为止
       */
    for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) {
      num =
        num.substring(0, num.length - (4 * i + 3)) +
        "," +
        num.substring(num.length - (4 * i + 3));
    }
    //将数据（符号、整数部分、小数部分）整体组合返回
    return sign + num + cents;
  }
};

export const replaceBankCard = (value: string): string => {
  if (value && value.length > 8) {
    return `${value.substring(0, 4)} ${"*"
      .repeat(value.length - 8)
      .replace(/(.{4})/g, `$1 `)}${value.length % 4 ? " " : ""}${value.slice(
      -4
    )}`;
  }

  return value;
};

export const getBankCardLastFour = (cardNumber: string): string => {
  return cardNumber.substr(-4);
};

export const isEmpty = (value: any): boolean => {
  if (value === undefined || value === null) {
    return true;
  }

  // 检查字符串是否为空
  if (typeof value === "string") {
    return value.trim() === "";
  }
  // 检查数组是否为空
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  // 检查对象是否没有可枚举属性
  if (typeof value === "object" && value !== null) {
    return Object.keys(value).length === 0;
  }
  // 其他类型视为非空
  return false;
};
/**
 * 将数字转换为万或亿，保留1位小数
 */
export const formatNumToMoney = (numStr: number | string) => {
  const num = Number(numStr);
  if (isNaN(num)) {
    return {};
  }
  if (num < 10000) {
    return {
      num: Number(num.toFixed(1)),
      unit: "",
    };
  }
  if (num >= 10000 && num < 100000000) {
    return {
      num: Number((num / 10000).toFixed(1)),
      unit: "万",
    };
  }
  return {
    num: Number((num / 100000000).toFixed(1)),
    unit: "亿",
  };
};

/**
 * 分割收藏夹标题，使用%分割，前面为标题，后面为副标题
 */
export const splitFavoriteTitle = (title: string) => {
  const splitIndex = title.indexOf("%");
  if (splitIndex !== -1) {
    return {
      title: title.slice(0, splitIndex),
      subTitle: title.slice(splitIndex + 1),
    };
  }
  return {
    title,
    subTitle: "",
  };
};

export { redirect };

import dd from "dingtalk-jsapi";
import { useEffect } from "react";

interface IPageTitleProps {
  /**
   * 页面标题
   */
  title?: string;
}
/**
 * 设置钉钉页面标题
 */
const usePageTitle = (data: IPageTitleProps) => {
  const { title } = data;
  const setTitle = (titleStr: string) => {
    try {
      dd.env.platform !== "notInDingTalk" &&
        dd.ready(() => {
          dd.biz.navigation.setTitle({
            title: titleStr,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    setTitle(title || "");
    return () => {
      setTitle("");
    };
  }, [title]);

  return {};
};
export default usePageTitle;

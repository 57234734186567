import type { IBannerList, ILink } from "@/types/config";

export enum ComponseTypeEnum {
  /**
   * 头部
   */
  Header = "header",
  /**
   * 轮播图
   */
  Banner = "banner",
  /**
   * 卡片
   */
  Card = "card",
  /**
   * 资讯列表
   */
  Information = "information",
  /**
   * 多元素卡片
   */
  DiverseCard = "diverseCard",
}
/**
 * 多元素卡片-底部
 */
export interface IDiverseCardFooter {
  /**
   * 底部图片
   */
  imageUrl?: string;
  /**
   * 底部文案
   */
  text?: string;
  link?: ILink;
}
export interface ICardHeader {
  title?: string;
  tags?: string[];
}
/**
 * 资讯-头部
 */
export interface IInformationHeader {
  title?: string;
  imageUrl?: string;
}

export interface IConfigItemHeader {
  type: ComponseTypeEnum.Header;
  title: string;
  logo: string;
  sub: {
    title: string;
    icon: string;
    link: ILink;
  };
}
export interface IConfigItemBanner {
  type: ComponseTypeEnum.Banner;
  bannerList: IBannerList[];
}
export interface IConfigItemCard {
  type: ComponseTypeEnum.Card;
  header: ICardHeader;
  iconList: IBannerList[];
  bannerList: IBannerList[];
}
export interface IConfigItemDiverseCard {
  type: ComponseTypeEnum.DiverseCard;
  cardList: IBannerList[];
  footer: IDiverseCardFooter;
}

export interface IConfigItemInfomation {
  type: ComponseTypeEnum.Information;
  header: IInformationHeader;
  params?: any;
}

export type IConfig = Array<
  | IConfigItemHeader
  | IConfigItemBanner
  | IConfigItemCard
  | IConfigItemDiverseCard
  | IConfigItemInfomation
>;

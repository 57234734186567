import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import styles from "./index.module.scss";
interface IAuthProps {
  children?: any;
}

/** 权限  */
const Auth: React.FC<IAuthProps> = (props) => {
  const data: any = useLoaderData();

  if (!data?.hasLogin) {
    return <div className={styles.fail} />;
  }

  return props.children;
};

export default React.memo(Auth);

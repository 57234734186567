import React from "react";
import { Image } from "antd-mobile";
import Icon from "@/common/Component/Icon";
import styles from "./index.module.scss";
import { useOpenUrl } from "@/hooks/useOpenUrl";
import { IConfigItemHeader } from "../../type";

interface IHeaderProps {
  hideMy?: boolean;
  data: IConfigItemHeader;
}

/** 头部  */
const Header: React.FC<IHeaderProps> = (props) => {
  const { hideMy, data } = props;
  const openUrl = useOpenUrl();
  return (
    <div className={styles.header}>
      <div className={styles.headerL}>
        <Image className={styles.logo} src={data.logo} />
        <div className={styles.title}>{data.title}</div>
      </div>
      {!hideMy && (
        <div
          className={styles.headerR}
          onClick={() => {
            data?.sub?.link && openUrl(data.sub.link);
          }}
        >
          <span className={styles.text}>{data?.sub?.title}</span>
          <Icon className={styles.iconQianBao} icon="icon-qianbao" />
        </div>
      )}
    </div>
  );
};

export default Header;

import React, { useEffect } from "react";
import MetroCard from "./Component/MetroCard";
// import Activity from "./Component/Activity";
import style from "./index.module.scss";
import usePageTitle from "@/hooks/usePageTitle";
import { useRequest } from "ahooks";
import { getBankCard } from "./Services";
import useUserStore from "@/store/global";
import { useShallow } from "zustand/react/shallow";
function Wallet() {
  const { data: backCardData, loading } = useRequest(getBankCard, {});
  usePageTitle({ title: "钱包" });
  const [dingUserInfo, getDingUser, corpId] = useUserStore(
    useShallow((state) => [state.dingUserInfo, state.getDingUser, state.corpId])
  );
  useEffect(() => {
    getDingUser();
  }, []);
  return (
    <div className={style.app}>
      <MetroCard
        detialRoute="/oneCard"
        // methodName="余额"
        bankCards={backCardData?.data}
        title={"我的余额"}
        subTitle={"添加余额支付"}
      />
      {/* <MetroCard detialRoute="/bankCardDetail" data={data?.bankCard} /> */}
    </div>
  );
}

export default Wallet;

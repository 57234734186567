import axios from "@/common/Utils/APIClient";

/**
 * 钉钉配置
 */
export const getDingConfig = (data: { url: string }): Promise<IRes> => {
  return axios.get<IRes>("/dmall_customer/ding/config", {
    param: data,
  });
};
interface IRes {
  code: string;
  message: string;
  result: Result;
  success: boolean;
}

interface Result {
  agentId: string;
  corpId: string;
  jsticket: string;
  nonceStr: string;
  signature: string;
  timeStamp: number;
  type: number;
}
